import { defaults } from "chart.js";
import { Charts } from "./charts";
import { Charts2 } from "./charts2";
import { Some } from "./some";

export const B = () => {
  return <div>
    <Charts2/>
  </div>
}
