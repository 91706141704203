import { useState } from "react"

export const Submit = () => {
  const [state,setState] = useState({})
  return <div>
    <input value={state.input} onChange={(e) => setState({...state,input: e.target.value})}/>
    {/* <input type="checkbox"></> */}
    <button onClick={() => {
      fetch('https://api.minascan.io/node/mainnet/v1/graphql', {
        method: 'POST',
        body: state.input,
        headers: {
          'content-type': 'application/json'
        }
      })
      .then(x => x.json())
      .then(x => alert('submit success, response: ' + JSON.stringify(x)))
      .catch(x => alert('err'))
    }}>submit</button>
  </div>
}
